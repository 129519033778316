import * as PropTypes from 'prop-types';
import Navbar from './Navbar';
import Footer from './Footer';
import GlobalAlert from './GlobalAlert';
import { classes, classNamer } from '../../lib/utils';
import LogOutButton from '../widgets/LogOutButton';

import './Layout.scss';

const cn = classNamer('Layout');

const Layout = ({ children, className, globalAlert, session, currentPageComponent }) => {
  return (
    <>
      <div className={classes(cn(), className)} id={className}>
        <main id="top">
          <Navbar currentPageComponent={currentPageComponent} />

          <div className={cn('content')}>
            <GlobalAlert content={globalAlert} />

            {children}
          </div>
          <Footer loggedIn={!!session} />

          {session && (
            <div className="alert alert-info mb-0">
              <div className="container text-center">
                <p>
                  You are logged in as <strong>{session.username}</strong>
                </p>
                <LogOutButton />
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  currentPageComponent: PropTypes.element,
};

export default Layout;
