import en from './en';

const defaultLanguage = en;
const i18n = { ...en };

/** @type {I18n} */
export default i18n;

const LANGUAGES = { en };

export function loadLanguage(languageName) {
  const language = LANGUAGES[languageName];
  if (!language) {
    throw new Error(`Unsupported language: ${languageName}`);
  }

  // Clean current translations
  Object.keys(i18n).forEach(key => {
    delete i18n[key];
  });

  // Load the default language first, so we'll have some fallbacks
  doLoadLanguage(defaultLanguage);

  // Then load the new language
  doLoadLanguage(language);
}

function doLoadLanguage(language) {
  Object.keys(language).forEach(key => {
    i18n[key] = language[key];
  });
}
