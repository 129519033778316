import React from 'react';
import { withRouter } from 'next/router';
import * as PropTypes from 'prop-types';

import { classes } from '../../lib/utils';
import Link from 'next/link';

const NavLink = ({
  href,
  className,
  prefetch,
  isActive = () => null,
  linkFor,
  router,
  children,
}) => {
  const activeClassName = {
    navbar: 'active',
    footer: 'disabled',
  };

  return (
    <Link prefetch={prefetch} href={href}>
      <a className={`nav-link ${classes(className, isActive(router) && activeClassName[linkFor])}`}>
        {children}
      </a>
    </Link>
  );
};

NavLink.propTypes = {
  href: PropTypes.string,
  prefetch: PropTypes.bool,
  className: PropTypes.string,
  linkFor: PropTypes.string,
  isActive: PropTypes.func,
};

export default withRouter(NavLink);
