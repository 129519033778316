const backendProtocol = process.env.BACKEND_PROTOCOL || 'https';
const backendHost = process.env.BACKEND_HOST || 'cms.xcalibra.com';
const backendPort = process.env.BACKEND_PORT || 443;

module.exports = {
  isProduction: process.env.NODE_ENV === 'production',
  gtmId: process.env.GTM_ID,
  backendUrl: `${backendProtocol}://${backendHost}:${backendPort}`,
  frontendUrl: process.env.FRONTEND_URL || 'https://xcalibra.com',
  exchangeUrl: process.env.EXCHANGE_URL || 'https://trade.xcalibra.com',
  exchangeAppUrl: process.env.EXCHANGE_APP_URL || 'https://app.xcalibra.com',
  articlesPerPage: process.env.ARTICLES_PER_PAGE,
  facebookAppToken: process.env.FACEBOOK_APP_TOKEN,
  cmcPageLink: 'https://coinmarketcap.com/exchanges/xcalibra',
  cmcCoinLink: 'https://coinmarketcap.com/currencies',
  matomo: {
    url: process.env.MATOMO_URL,
    siteId: process.env.MATOMO_SITE_ID,
    cookieDomain: process.env.MATOMO_COOKIE_DOMAIN,
    domains: (process.env.MATOMO_DOMAINS || '').split(',').map(d => d.trim()),
  },
  sentryDsn: process.env.FRONTEND_SENTRY_DSN,
  frontendEnvironment: /xcalibra\.com$/.test(backendHost)
    ? 'production'
    : /xcalibra\.icu$/.test(backendHost)
    ? 'staging'
    : 'development',
};
