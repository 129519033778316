export default /** @lends I18n.prototype */ {
  product: 'Xcalibra',
  language: 'en',
  layout: {
    languageBar: {
      language: 'Language',
    },
  },
  homepageSEOTitle: 'Trade Cryptocurrencies Securely and Simply Today - Xcalibra',
  homepageTitle: 'Trade Cryptocurrencies Securely and Simply Today',
  homepageSEODescription:
    'Xcalibra is a high-performance crypto exchange where you can Buy Safex, Bitcoins, ETH and other cryptocurrency.',

  websiteTermsSEODescription:
    'Terms and service of Xcalibra is written here, please read before signing up.',

  privacyPolicySEODescription:
    'Xcalibra is a cryptocurrency exchange which sells Safex coins, this is the privacy policy. Please read before signing in.',

  statusPageSEODescription:
    'Xcalibras Fees has never been lower! If you want to learn more read this. This includes Trade fees, deposits and withrawals etc.',

  newsPageSEOTitle: 'News and announcements - Xcalibra Exchange',
  newsPageTitle: 'News and announcements',
  newsPageDescription:
    'Read the latest news from Xcalibra! Including Safex news, BTC news and ETH news.',
  newsPageSiteName: 'Xcalibra News',

  blogpageSEOTitle: 'Blog about Cryptocurrency and Trading - Xcalibra Exchange',
  blogpageTitle: 'Blog about Cryptocurrency and Trading',
  blogpageDescription:
    "Want to learn how to trade cryptocurrency? We got you covered from A-Z! Here's our Blog about the latest in the cryptocurrency world.",
  blogpageSiteName: 'Xcalibra Blog',

  countriesListPageTitleSEO: 'List of available countries - Xcalibra Exchange',
  countriesListPageTitle: 'Global reach for the global market',
  countriesListDescription:
    'Xcalibra is available in 150+ countries and territories and supports 15 national currencies. See if your country is on the list and trade at Xcalibra exchange.',

  statusPageTitle: 'Status and fees',
  privacyPolicyPageTitle: 'Privacy Policy',

  kbPageSEOTitle: 'Knowledge Base - Xcalibra Exchange',
  kbPageTitle: 'Knowledge Base',
  kbPageSEODescription:
    'Looking for answers about the Xcalibra exchange? You are in the right place. We explained everything in step-by-step guides so you can join and trade easily.',
  kbpageSiteName: 'Xcalibra Knowledge Base',

  depositYourCrypto: 'Deposit your crypto assets free of charge and trade',
  getCryptoFast: 'Get Bitcoin and Ethereum ultra-fast with 14 national currencies',
  buySimple: 'Buy simply with Visa or MasterCard',
  globalReachTitle: 'Global Reach',
  globalReachTextOne: 'Servicing users across 100+ countries',
  globalReachTextTwo: 'Check if your country is on the',
  globalReachTextThree: 'list',
  selectiveAssetTitle: 'Selective asset listings',
  selectiveAssetText:
    'Listing the most promising cryptocurrencies and tokens with innovative technology',
  reliableSupportTitle: 'Reliable Support',
  reliableSupportTextOne: '72-hour maximum ticket response time',
  reliableSupportTextTwo: 'Live Chat support for premium accounts',
  smoothPerformanceTitle: 'Smooth Performance',
  smoothPerformanceText:
    'Execute trades at lightning speed on our high-performance custom-built trading platform',
  tradingBotTitle: 'Trading Bot-Friendly',
  tradingBotTextOne: 'Integrate with our',
  tradingBotTextTwo: 'API',
  tradingBotTextThree: 'and benefit from example code for several popular programming languages',
  ultraFastTitle: 'Ultra-Fast Trades',
  ultraFastText:
    'Built upon the SUN Protocol, your trades are executed at lightning speed across highly available, fibre-optic network infrastructure',
  firstGradeTitle: '1st grade security',
  firstGradeTextOne: '100% of funds in multi-sig cold storage',
  firstGradeTextTwo: 'Automated backups, DDoS mitigation',
  firstGradeTextThree: 'IP security & access restrictions, 2FA',
  zeroFeeTitle: 'Zero fee deposits on crypto',
  zeroFeeText: 'State-of-the-art digital asset exchange that puts your trading needs first',
  zeroFeeBtn: 'Create Your Account',
  home: 'Home',
  news: 'News',
  blog: 'Blog',
  careers: 'Careers',
  login: 'Log in',
  managed: 'Managed',
  register: 'Register',
  viewMarkets: 'View Markets',
  buyWithCard: 'Buy With Card',
  navigation: 'Navigation',
  help: 'Help',
  knowledgeBase: 'Knowledge Base',
  apiDocs: 'API Documentation',
  contactSupport: 'Contact Support',
  policies: 'Policies',
  statusAndFees: 'Status and Fees',
  apiAggrement: 'API Agreement',
  privacyPolicy: 'Privacy Policy',
  buyOrSell: 'Buy or Sell',
  allowedCountries: 'Allowed Countries',
  exchangeTerms: 'Exchange Terms',
  websiteTerms: 'Website Terms',
  coinMarketCap: 'CoinMarketCap',
  newsFeeds: 'News feeds',
  blogFeeds: 'Blog feeds',
  rss: 'RSS',
  atom: 'Atom',
  copyRight: 'Copyright',
  allRightsReserved: 'all rights reserved',
  date: 'Date',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  backToTop: 'Back to top',
  allArticles: 'All articles',
  articles: 'Articles',
  loadMoreArticles: 'Load more articles',
  markets: 'Markets',
  pair: 'Pair',
  tradeFee: 'Trade Fee',
  minOrder: 'Min order',
  status: 'Status',
  depositsAndWithdrawals: 'Deposits & Withdrawals',
  instrument: 'Instrument',
  confirmations: 'Confirmations',
  deposit: 'Deposit',
  withdrawal: 'Withdrawal',
  fee: 'Fee',
  deposits: 'Deposits',
  depositsTextOne:
    'Crypto deposits are processed in real time 24/7. They are credited to your account once the transaction reaches the minimum confirmation times on its respective blockchain.',
  depositsTextTwo:
    'Fiat deposits are processed only during the Business Hours of the respective currency. Monday- Friday daily deposits are processed except on banking holidays',
  withdrawals: 'Withdrawals',
  withdrawalsTextOne:
    "Crypto withdrawals are processed at least once per day. All withdrawals undergo auditing and verification prior to being authorized to be released. These measures are in place to protect the user experience of this exchange. Crypto withdrawals are processed by at least 12:00pm UTC when all withdrawals for the previous 24 hours will be processed. Xcalibra utilizes automation in the withdrawal process, however, only after an auditor's scrutiny.",
  withdrawalsTextTwo:
    'Fiat withdrawals are processed daily by noon of the respective national headquarters for the previous 24 hours of withdrawal requests.',
  btcInstrumentConfirmationLookup: '3 blocks',
  sfxInstrumentConfirmationLookup: '40 blocks',
  sftInstrumentConfirmationLookup: '40 blocks',
  ethInstrumentConfirmationLookup: '15 blocks',
  rsdInstrumentConfirmationLookup: 'Daily',
  weAreCommited:
    ' We are committed to safeguarding the privacy of our website visitors; this policy sets out how we will treat your personal information.',
  whatInfoDoWeCollectTitle: 'What information do we collect?',
  whatInfoDoWeCollectTextOne: 'We may collect, store and use the following kinds of personal data:',
  whatInfoDoWeCollectTextTwo:
    'information about your computer and your visits to and use of this website (including your IP address, geographical location, browser type, referral source, length of visit, number of page vie`ws and searches you make);',
  whatInfoDoWeCollectTextThree:
    "information relating to any transactions carried out in this website, or in relation to this website, including information relating to any purchases or requests you make of third party's goods or services using this website;",
  whatInfoDoWeCollectTextFour: 'Other information that you choose to send to us.',
  cookiesTitle: 'Cookies',
  cookiesTextOne:
    "A cookie consists of information sent by a web server to a web browser, and stored by the browser. The information is then sent back to the server each time the browser requests a page from the server. This enables the web server to identify and track the web browser. We may use both “session” cookies and “persistent” cookies on the website. We will use the session cookies to: keep track of you whilst you navigate the website; and keep you logged in. We will use persistent cookies to: enable our website to were last interested in. Session cookies will be deleted from your computer when you close your browser. Persistent cookies will remain stored on your computer until deleted, or until they reach a specified expiry date. We may use Google Analytics to analyse the use of this website. Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers. The information generated relating to our website is used to create reports about the use of the website. Google will store this information. Google's privacy policy is available",
  cookiesTextTwo: 'here',
  cookiesTextThree:
    'Most browsers allow you to refuse to accept cookies. (For example, in Internet Explorer you can refuse all cookies by clicking “Tools”, “Internet Options”, “Privacy”, and selecting “Block all cookies” using the sliding selector.) This will, however, have a negative impact upon the usability of many websites.',
  usingYourDataTitle: 'Using your personal data',
  usingYourDataTextOne:
    'Personal data submitted on this website will be used for the purposes specified in this privacy policy or in relevant parts of the website. We may use your personal information to provide services to you and to:',
  usingYourDataTextTwo: 'administer the website;',
  usingYourDataTextThree: 'improve your browsing experience by personalising the website;',
  usingYourDataTextFour: 'enable your use of the services available on the website;',
  usingYourDataTextFive: 'send you general (non-marketing) communications;',
  usingYourDataTextSix: 'send you email notifications which you have specifically requested;',
  usingYourDataTextSeven:
    'provide third parties with statistical information about our users – but this information will not be used to identify any individual user;',
  usingYourDataTextEight:
    'deal with enquiries and complaints made by or about you relating to the website. We will not provide your personal information to any third parties for the purpose of direct marketing.',
  disclosuresTitle: 'Disclosures',
  disclosuresTextOne:
    'We may disclose information about you to any of our employees, officers, agents, suppliers, collaborators, distribution partners, customers or subcontractors insofar as reasonably necessary for the purposes as set out in this privacy policy. In addition, we may disclose information about you:',
  disclosuresTextTwo: 'to the extent that we are required to do so by law;',
  disclosuresTextThree:
    'in connection with any legal proceedings or prospective legal proceedings;',
  disclosuresTextFour:
    'in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);',
  disclosuresTextFive:
    'to the purchaser (or prospective purchaser) of any business or asset which we are (or are contemplating) selling. Except as provided in this privacy policy, we will not provide your information to third parties.',
  intercontinentalDataTransferTitle: 'International data transfers',
  intercontinentalDataTransferText:
    'Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this privacy policy. If you are in the European Economic Area (EEA), information which you provide may be transferred to countries (including the United States) which do not have data protection laws equivalent to those in force in the EEA. In addition, information that you submit for publication on the website will be published on the internet and may be available, via the internet, around the world. By using our website you expressly agree to such transfers of personal information.',
  dataSecurityTitle: 'Security of your personal data',
  dataSecurityText:
    'We take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information. We will store all the personal information you provide on our secure (password protected) servers. Of course, data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.',
  policyAmendmentTitle: 'Policy amendments',
  policyAmendmentText:
    'We may update this privacy policy from time-to-time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes.',
  yourRightsTitle: 'Your rights',
  yourRightsText:
    'You may instruct us to provide you with any personal information we hold about you. You may instruct us not to process your personal data for any purposes by email at any time. (In practice, you will usually either expressly agree in advance to our use of your personal data for marketing purposes, or we will provide you with an opportunity to opt-out of the use of your personal data for marketing purposes.)',
  thirdPartyWebsitesTitle: 'Third party websites',
  thirdPartyWebsitesText:
    'The website may contain links to other websites, and, upon your request, transmit your information to third party websites. We are not responsible for the privacy policies or practices of third party websites.',
  updatingInfoTitle: 'Updating information',
  updatingInfoText:
    'Please let us know if the personal information which we hold about you needs to be corrected or updated.',
  contactTitle: 'Contact',
  contactTextOne:
    'If you have any questions about this privacy policy or our treatment of your personal data, please write to us by email to',
  contactTextTwo: 'contact@xcalibra.com',
  hiHowCanWeHelp: 'Hi. How can we help?',
  searchKbArticlesNoResult: 'No results',
  searchKbArticlesTryNewTerm: 'Please check your spelling or try another term',
  searchingForArticles: 'Searching...',
  quickFind: 'Quick find',
  findYourLocation: 'Type your location',
  sorryWeAreCurrentlyNotSupportingTextOne: 'Sorry, we are currently not supporting ',
  sorryWeAreCurrentlyNotSupportingTextTwo: 'If You have any questions, please contact our ',
  sorryWeAreCurrentlyNotSupportingTextThree: 'support team',
  sorryWeAreCurrentlyNotSupportingTextFour: ', and we will answer You as soon as posible.',
  latestArticles: 'Latest articles',
  noRecentActivity: 'No recent activity',
  noOpenPositions: 'Currently there are no open positions.',
  tableOfContent: 'Table of content',
  noArticlesYet: 'No articles yet',
  categoryArticlesListEmpty: 'No articles yet',
  af: 'Africa',
  na: 'North America',
  sa: 'South America',
  eu: 'Europe',
  oc: 'Oceania',
  as: 'Asia',
  an: 'Antartica',
  uncategorized: 'Uncategorized',
  unpublished: 'Unpublished',
  unserialized: 'Unserialized',
  careersAtXcalibra: 'CAREERS AT XCALIBRA',
  careersPageSEOTitle: 'Become a part of our team - Xcalibra Careers',
  careersPageSEODescription:
    "Join the Xcalibra team in building the world's leading crypto exchange platform. Click the link and check the benefits of working in the industry of tomorrow.",
  careersPageSiteName: 'Xcalibra Careers',
  careersPageTitle: 'Become a part of our team',
  careersPageDescription:
    'Join us in bringing the crypto market to every household by enabling people to make their money digital. The future of finance is crypto, join the industry of tomorrow... today!',
  careersCareersSectionTitle: 'Open Positions',
  careersExchangeSectionTitle:
    'Create the world-class crypto exchange from Denver, Colorado with us',
  careersExchangeSectionTextOne:
    'Imagine thousands of people from 150+ countries exchanging popular cryptocurrencies such as bitcoin, ethereum, and safex every second. That is Xcalibra – an exchange platform that enables its users to trade crypto assets in real time.',
  careersExchangeSectionTextTwo:
    'We have already built a working platform, but there is still a long way to go. There are a number of new functionalities and ideas we would like to implement and we are looking for experienced developers to be a part of the growth.',
  careersExchangeSectionTextThree:
    "You won't be dealing with clients, salespeople, or being micromanaged. Xcalibra is an in-house project and your only concern is the code. Your good work will not only be rewarded but also recognized in the crypto space as well.",
  careersBenefitsSectionTitle: 'Work and enjoy at Xcalibra',
  careersBenefitsSectionDescription:
    'We make sure our people are at their best while working with us. That is why we have provided these benefits and perks.',

  careersBenefitsSectionBenefitTitleOne: 'Good salary',
  careersBenefitsSectionBenefitTextOne:
    'We want you to feel safe and appreciated while working at Xcalibra. What is the current market rate for your services? We will meet that, plus more.',

  careersBenefitsSectionBenefitTitleTwo: 'As A team',
  careersBenefitsSectionBenefitTextTwo:
    'You will get your own place to work in an effective, friendly team. Our flat organizational structure empowers the group to succeed together.',

  careersBenefitsSectionBenefitTitleThree: 'Free lunch',
  careersBenefitsSectionBenefitTextThree:
    'No need to go on a scavenger hunt for a tasty and nutritious meal. We have prepared one for you, free of charge. Snacks and drinks included.',

  careersBenefitsSectionBenefitTitleFour: 'Great hardware',
  careersBenefitsSectionBenefitTextFour:
    'Choose your preferred hardware, whether it is a Mac or PC. Get the machine you need to make the most out of your skills and time.',

  careersBenefitsSectionBenefitTitleFive: 'Sponsored trips',
  careersBenefitsSectionBenefitTextFive:
    'Conferences and meetups are a great chance to learn more and expand your network. Stay at the top of the game while working at Xcalibra.',

  careersBenefitsSectionBenefitTitleSix: 'Stock options',
  careersBenefitsSectionBenefitTextSix:
    "Stick with us in achieving the Company's goals and we will award you for good work. Diligence is highly appreciated with us.",

  careersJoinUsSectionTitle: 'Join us in building the future of finance today!',
  careersJoinUsSectionButton: 'EXPLORE ROLES',

  newsNavigationTitle: 'News history',
};
