import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import R, { isCurrentRoute } from '../../lib/routes';
import { classes } from '../../lib/utils';
import { frontendEnvironment } from '../../lib/settings';
import { Button, BUTTON_VARIANTS } from '../widgets/Button';
import NavLink from '../widgets/NavLink';
import i18n from '../../translations/i18n';

import './Navbar.scss';

class Navbar extends React.PureComponent {
  NAV_SCROLLED_CLASSNAME = 'scrolled';

  static propTypes = {
    menuOpen: PropTypes.bool,
    scrolled: PropTypes.bool,
  };

  state = {
    menuOpen: false,
    scrolled: false,
  };

  _mounted = false;

  toggleMenu = () =>
    this._mounted &&
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
    }));

  openOrCloseMenuBasedOnWindowSize = () => {
    if (document.body.clientWidth >= 985) {
      this._mounted && this.setState({ menuOpen: true });
    } else {
      this._mounted && this.setState({ menuOpen: false });
    }
  };

  onScroll = () => {
    // Mobile only
    if (window.innerWidth <= 1000) {
      if (window.scrollY > 10) {
        !this.state.scrolled && this.setState({ scrolled: true });
      } else {
        this.state.scrolled && this.setState({ scrolled: false });
      }
    }
  };

  handleRouteChange = () => {
    if (this._mounted && this.state.menuOpen) {
      this.setState({
        menuOpen: false,
      });
    }
  };

  componentDidMount() {
    this._mounted = true;
    this.openOrCloseMenuBasedOnWindowSize();
    window.addEventListener('resize', this.openOrCloseMenuBasedOnWindowSize, false);
    window.addEventListener('scroll', this.onScroll);
    this.props.router.events.on('routeChangeStart', this.handleRouteChange);
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener('resize', this.openOrCloseMenuBasedOnWindowSize, false);
    window.removeEventListener('scroll', this.onScroll);
    this.props.router.events.off('routeChangeStart', this.handleRouteChange);
  }

  render() {
    const currentPageComponent =
      this.props.currentPageComponent &&
      React.cloneElement(this.props.currentPageComponent, {
        // NOTE: Due to next.js bug in our version, routeChangeStart will not trigger for hash changes. So we have to
        //       implement a hack like this.
        //       https://github.com/vercel/next.js/issues/13659
        // TODO: When we move on to next 9.x, we can remove this mechanism.
        onHashNavigation: this.handleRouteChange,
      });

    return (
      <nav
        className={`Navbar navbar navbar-expand-lg navbar-light ${
          this.state.scrolled ? this.NAV_SCROLLED_CLASSNAME : ''
        } ${
          frontendEnvironment === 'development'
            ? 'Navbar-development'
            : frontendEnvironment === 'staging'
            ? 'Navbar-staging'
            : ''
        }`}
      >
        <div className="container top-wrap">
          <a className="navbar-brand" href="/">
            <img
              src="/static/xc-dark.png"
              className="d-inline-block align-top"
              style={{ height: '2.5rem', width: 'auto' }}
              alt="Xcalibra logo"
            />
          </a>
          <button
            className={`navbar-toggler ${this.state.menuOpen ? 'border-0' : ''}`}
            type="button"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.toggleMenu}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {this.state.menuOpen && (
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="mr-auto" />
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                    href={R.home}
                    isActive={router => isCurrentRoute(router, R.home)}
                    linkFor="navbar"
                    className={classes('px-4', 'text-center')}
                  >
                    {i18n.home}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      href={R.managed}
                      isActive={router => isCurrentRoute(router, R.managed)}
                      linkFor="navbar"
                      className={classes('px-4', 'text-center')}
                  >
                    {i18n.managed}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    href={R.news}
                    isActive={router => isCurrentRoute(router, R.news)}
                    linkFor="navbar"
                    className={classes('px-4', 'text-center')}
                  >
                    {i18n.news}
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    href={R.blog}
                    isActive={router => isCurrentRoute(router, R.blog)}
                    linkFor="navbar"
                    className={classes('px-4', 'text-center')}
                  >
                    {i18n.blog}
                  </NavLink>
                </li>

                <li className="nav-item">
                  <Button
                    variant={BUTTON_VARIANTS.primary}
                    className="px-4 w-100"
                    href={R.exchangeLogin}
                  >
                    {i18n.login}
                  </Button>
                </li>
                {currentPageComponent && (
                  <li className="nav-item current-page-component">{currentPageComponent}</li>
                )}
              </ul>
            </div>
          )}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  currentPageComponent: PropTypes.element,
};

export default withRouter(Navbar);
