const fetch = require('isomorphic-unfetch');
const qs = require('querystring');
import R from './routes';

const settings = require('./settings');

let baseUrl = settings.backendUrl;
if (!baseUrl) {
  console.error(`Warning: BACKEND_URL is not configured`);
}
if (baseUrl.endsWith('/')) {
  baseUrl = baseUrl.slice(0, -1);
}

let exchangeAppUrl = settings.exchangeAppUrl;
if (!exchangeAppUrl) {
  console.error(`Warning: EXCHANGE_APP_URL is not configured`);
}
if (exchangeAppUrl.endsWith('/')) {
  exchangeAppUrl = exchangeAppUrl.slice(0, -1);
}

const METHODS = {
  get: 'get',
  post: 'post',
};

class RequestError extends Error {
  constructor(message, code, params) {
    if (Array.isArray(message)) {
      // Convert strapi's response format
      message =
        (message[0] &&
          message[0].messages &&
          message[0].messages[0] &&
          message[0].messages[0].message) ||
        JSON.stringify(message);
    }

    super(message);

    this.code = code;

    if (params) {
      for (const key in params) {
        if (
          Object.prototype.hasOwnProperty.call(params, key) &&
          key !== 'message' &&
          key !== 'stack' &&
          key !== 'code'
        ) {
          this[key] = params[key];
        }
      }
    }
  }
}

const makeBackendUrl = (path, query = undefined) => {
  if (path[0] !== '/') {
    path = '/' + path;
  }

  if (query) {
    path += '?' + qs.stringify(query);
  }

  return `${baseUrl}${path}`;
};

const makeFeedUrl = (type, pageName) => {
  return makeBackendUrl(`${pageName}/feed/${type}`);
};

const makeExchangeAppUrl = path => {
  if (path[0] !== '/') {
    path = '/' + path;
  }

  return `${exchangeAppUrl}${path}`;
};

// *********************************************************************************************************************

const doRequest = async (session, method, url, body = undefined) => {
  let res;
  let data;
  try {
    const req = {
      method,
      headers: {},
    };

    if (body) {
      req.body = JSON.stringify(body);
      req.headers['Content-Type'] = 'application/json';
    }

    if (session) {
      req.headers['Authorization'] = `Bearer ${session.accessToken}`;
    }
    res = await fetch(url, req);

    data = await res.json();
  } catch (err) {
    if (res) {
      throw new RequestError(res.statusText, res.status);
    }
    throw new RequestError(err.message || err || 'Request error', 0);
  }

  if (res && data && res.status >= 400) {
    throw new RequestError(data.message, res.status, data);
  }

  return data;
};

const getNewsArticles = async (session, page, pageSize = settings.articlesPerPage) => {
  return doRequest(
    session,
    METHODS.get,
    makeBackendUrl(R.news, {
      page,
      'page-size': pageSize,
    })
  );
};

const getNewsHeadlines = async session => {
  return doRequest(session, METHODS.get, makeBackendUrl(`${R.news}/list/basic`));
};

const getBlogArticles = async (session, page, pageSize = settings.articlesPerPage) => {
  return doRequest(
    session,
    METHODS.get,
    makeBackendUrl(R.blog, {
      page,
      'page-size': pageSize,
    })
  );
};

const getBlogArticleBySlug = async (session, slug) => {
  return doRequest(session, METHODS.get, makeBackendUrl(`${R.blog}/by-slug/${slug}`));
};

const getNewsArticleByUrl = async (session, url) => {
  return doRequest(session, METHODS.get, makeBackendUrl(`news/by-url/${url}`));
};

const getKbArticles = async (session, page, pageSize = settings.articlesPerPage) => {
  return doRequest(
    session,
    METHODS.get,
    makeBackendUrl(`/kb/articles`, {
      page,
      'page-size': pageSize,
    })
  );
};

const getKBCategories = async session => {
  return doRequest(session, METHODS.get, makeBackendUrl(`/kb/categories`));
};

const getKbArticlesByCategorySlug = async (session, categorySlug) => {
  return doRequest(
    session,
    METHODS.get,
    makeBackendUrl(`/kb/categories/by-slug/${categorySlug}/articles`)
  );
};

const searchKbArticlesByTitle = async (session, title) => {
  return doRequest(session, METHODS.get, makeBackendUrl(`/kb/articles/by-title/${title}`));
};

const findKbCategoryBySlug = async (session, slug) => {
  return doRequest(session, METHODS.get, makeBackendUrl(`/kb/categories/by-slug/${slug}`));
};

const getKbArticleBySlug = async (session, slug) => {
  return doRequest(session, METHODS.get, makeBackendUrl(`/kb/articles/by-slug/${slug}`));
};

const getCareers = async session => {
  return doRequest(session, METHODS.get, makeBackendUrl(`/careers`));
};

/**
 * @return {Promise<{jwt, user: {display_name}}>}
 */
const login = (identifier, password) => {
  return doRequest(null, METHODS.post, makeBackendUrl('/auth/local'), { identifier, password });
};

const getPairPolicy = async () => {
  return doRequest(null, METHODS.get, makeExchangeAppUrl('/api/public/v1/pairs'));
};

const getInstrumentPolicy = async () => {
  return doRequest(null, METHODS.get, makeExchangeAppUrl('/api/public/v1/instruments'));
};

// *********************************************************************************************************************

module.exports = {
  makeBackendUrl,
  makeFeedUrl,

  // News
  getNewsArticleByUrl,
  getNewsArticles,
  getNewsHeadlines,

  // Blog
  getBlogArticleBySlug,
  getBlogArticles,

  // Knowledge Base
  getKbArticles,
  getKBCategories,
  getKbArticlesByCategorySlug,
  searchKbArticlesByTitle,
  findKbCategoryBySlug,
  getKbArticleBySlug,

  // Careers
  getCareers,

  login,

  getPairPolicy,
  getInstrumentPolicy,
};
