import * as PropTypes from 'prop-types';

import { classes, classNamer } from '../../lib/utils';
import Layout from './Layout';

import './PageLayout.scss';

const cn = classNamer('PageLayout');

const PageLayout = ({ children, title, subTitle, className, ...passProps }) => {
  return (
    <Layout title={title} className={classes(className, cn())} {...passProps}>
      <div className="container">
        <h1 className={cn('title')}>{title}</h1>
        {subTitle && <p className={cn('subtitle')}>{subTitle}</p>}
        <div className={cn('content')}>{children}</div>
      </div>
    </Layout>
  );
};

PageLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  currentPageComponent: PropTypes.element,
};

export default PageLayout;
