const Routes = require('next-routes');

const router = new Routes();

// Add main and nested routes (if we have more than 1 nested route, place them in array)
router.add('blog', '/blog/:slug');
router.add('news', '/news/:url');
router.add('kb', ['/kb/categories/:categorySlug', '/kb/articles/:articleSlug']);
router.add('careers');
router.add('managed');

module.exports = router;
