import { Link } from '../../router';
import R, { isCurrentRoute } from '../../lib/routes';
import { makeFeedUrl } from '../../lib/backend';
import NavLink from '../widgets/NavLink';
import settings from '../../lib/settings';
import { CoinMarketCapIcon } from '../../../static/icons/svg_icons';
import i18n from '../../translations/i18n';

import './Footer.scss';

const Footer = ({ loggedIn }) => (
  <footer className="Footer">
    <div className="container">
      <div className="row Footer-row">
        <div className="col-md-3 Footer-column Footer-column-first">
          <div className="Footer-column-content">
            <a href="#top" title="Back to top">
              <img src="/static/footer-logo.png" alt="Xcalibra logo" className="Footer-logo" />
            </a>

            <div className="Footer-social">
              <Link href={R.facebook}>
                <a className="nav-link" target="_blank" title="Visit Xcalibra Facebook Page">
                  <img src="/static/facebook.png" alt="Xcalibra Facebook Page" />
                </a>
              </Link>{' '}
              <Link href={R.medium}>
                <a className="nav-link" target="_blank" title="Visit Xcalibra Medium Page">
                  <img src="/static/medium.png" alt="Xcalibra Medium Page" />
                </a>
              </Link>{' '}
              <Link href={R.twitter}>
                <a className="nav-link" target="_blank" title="Visit Xcalibra Twitter Page">
                  <img src="/static/twitter.png" alt="Xcalibra Twitter Page" />
                </a>
              </Link>{' '}
            </div>
          </div>
        </div>

        <div className="col-md-3 Footer-column">
          <div className="Footer-column-content">
            <div className="Footer-block">
              <h5>{i18n.navigation}</h5>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <NavLink
                    prefetch
                    href={R.home}
                    isActive={router => isCurrentRoute(router, R.home)}
                    linkFor="footer"
                  >
                    {i18n.home}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    prefetch
                    linkFor="footer"
                    href={R.news}
                    isActive={router => isCurrentRoute(router, R.news)}
                  >
                    {i18n.news}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    prefetch
                    href={R.blog}
                    linkFor="footer"
                    isActive={router => isCurrentRoute(router, R.blog)}
                  >
                    {i18n.blog}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    href={R.careers}
                    isActive={router => isCurrentRoute(router, R.careers)}
                    linkFor="footer"
                  >
                    {i18n.careers}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.exchangeLogin}>{i18n.login}</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-3 Footer-column">
          <div className="Footer-column-content">
            <div className="Footer-block">
              <h5>{i18n.help}</h5>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <NavLink href={R.knowledgeBase} className="NavLink nav-link">
                    {i18n.knowledgeBase}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.apiDocumentation}>{i18n.apiDocs}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.support}>{i18n.contactSupport}</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-3 Footer-column">
          <div className="Footer-column-content">
            <div className="Footer-block">
              <h5>{i18n.policies}</h5>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <NavLink href={R.statusPage}>{i18n.statusAndFees}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.apiAgreement}>{i18n.apiAggrement}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.privacyPolicy}>{i18n.privacyPolicy}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.countriesList}>{i18n.allowedCountries}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.termsOfUse}>{i18n.exchangeTerms}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink href={R.websiteTerms}>{i18n.websiteTerms}</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="Footer-bottom">
      <div className="container">
        <p>
          <span className="cmc">
            <CoinMarketCapIcon /> &nbsp;{i18n.coinMarketCap}:&nbsp;
            <a href={settings.cmcPageLink} target="_blank">
              <span>{i18n.product}</span>
            </a>{' '}
          </span>
          {i18n.newsFeeds}:{' '}
          <a href={makeFeedUrl('rss', R.news)} target="_blank">
            {i18n.rss}
          </a>{' '}
          <a href={makeFeedUrl('atom', R.news)} target="_blank">
            {i18n.atom}
          </a>
          <br />
          {i18n.blogFeeds}:{' '}
          <a href={makeFeedUrl('rss', R.blogFeed)} target="_blank">
            {i18n.rss}
          </a>{' '}
          <a href={makeFeedUrl('atom', R.blogFeed)} target="_blank">
            {i18n.atom}
          </a>
          <br />
          {i18n.copyRight} {new Date().getFullYear()} {i18n.product}, {i18n.allRightsReserved}.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
