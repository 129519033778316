import settings from './settings';
import { dateDisplay } from './selectors';

export const exchangeRoute = path => {
  return `${settings.exchangeUrl}/${path}`;
};

export const externalUrl = path => {
  return `${settings.frontendUrl}/${path}`;
};

export const isCurrentRoute = (router, baseUrl) => {
  return router.pathname === baseUrl;
};

const R = {
  // PAGES
  home: '/',
  isHome: router => router.pathname === '/',

  // BLOG
  blog: '/blog',
  blogArticle: article => `${R.blog}/${article.slug}`,
  blogFeed: '/articles',

  // NEWS
  news: '/news',
  buildNewsArticleUrl: article =>
    `${article.title}-${dateDisplay(article.published_at)}-${article.id}`.replace(
      /\.|\:|\_| |\(|\)/gi,
      ''
    ),
  newsArticle: article => `${R.news}/${R.buildNewsArticleUrl(article)}`,

  // EXCHANGE
  exchange: exchangeRoute(''),
  trading: pair => exchangeRoute(`exchange/${pair}`),
  exchangeRegister: exchangeRoute('register'),
  exchangeLogin: exchangeRoute('login'),
  exchangeBuyWithCard: exchangeRoute('buy-with-card'),
  exchangeDeposit: instrument => exchangeRoute(`balances/deposit/${instrument}`),
  exchangeWithdraw: instrument => exchangeRoute(`balances/withdraw/${instrument}`),

  // POLICIES
  deprecatedTradingFeeSchedule: '/trading-fee-schedule',
  statusPage: '/status',
  apiAgreement: '/api-agreement',
  privacyPolicy: '/privacy-policy',
  websiteTerms: '/website-terms',
  termsOfUse: '/terms-of-use',
  countriesList: '/countries-list',
  agTerms: '/ag-terms',
  agPrivacy: '/ag-privacy',

  // Knowledge Base
  knowledgeBase: '/kb',
  kbCategoryBySlug: category => `${R.knowledgeBase}/categories/${category.slug}`,
  kbArticleBySlug: article => `${R.knowledgeBase}/articles/${article.slug}`,
  kbArticlesPage: paginationPage => `${R.knowledgeBase}?page=${paginationPage}`,

  // Careers
  careers: '/careers',

  // Managed
  managed: '/managed',

  // EXTERNAL
  support: 'https://xcalibra.zendesk.com/hc/en-us/requests/new',
  apiDocumentation: 'https://api-docs.xcalibra.com',

  // SOCIAL MEDIA
  facebook: 'https://www.facebook.com/Xcalibra-Exchange-103611601125595',
  medium: 'https://medium.com/@tradexcalibra',
  twitter: 'https://twitter.com/tradexcalibra',
};

export default R;
