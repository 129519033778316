import { format } from 'date-fns';
import settings from './settings';

export const userDisplayName = user => (user ? user.display_name || user.username : '???');

export const articleDisplayDate = article => article.published_at || article.updated_at;

export const dateDisplay = date => {
  date = new Date(date);

  return format(date, 'MMM Do YYYY');
};

export const monthDateDisplay = date => {
  date = new Date(date);

  return format(date, 'MMM Do');
};
export const dayInMonthDisplay = date => {
  date = new Date(date);

  return format(date, 'Do');
};

export const createFrontendPageUrl = page => {
  if (page[0] !== '/') {
    return settings.frontendUrl + '/' + page;
  }
  return settings.frontendUrl + page;
};

/**
 * @type {IImageInfo}
 */
export const fallbackImageInfo = {
  url: createFrontendPageUrl('/static/fallback_image.png'),
  width: 1920,
  height: 1080,
  alt: 'Xcalibra', // Used in some places
};

/**
 * @param {IStrapiImage} image
 * @param alt
 * @param {IImageInfo} fallback
 * @return {IImageInfo}
 */
export const resolveStrapiImage = (image, alt = undefined, fallback = fallbackImageInfo) => {
  // NOTE: There is some strange circular dependency issue if we import this at the top
  const { makeBackendUrl } = require('./backend');

  if (!image) {
    return {
      ...fallback,
      alt: alt || fallback.alt,
    };
  }

  const [width, height] = [image.width, image.height].map(val => {
    const num = Number(val);
    return num || num === 0 ? num : undefined;
  });

  return {
    url: makeBackendUrl(image.url),
    alt: alt || image.alternativeText,
    width,
    height,
  };
};
