import React from 'react';
import * as PropTypes from 'prop-types';

import './GlobalAlert.scss';

const GlobalAlert = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className="GlobalAlert">
      <div className="container">
        <div className="GlobalAlert-content">{content}</div>
      </div>
    </div>
  );
};

GlobalAlert.propTypes = {
  content: PropTypes.string,
};

export default GlobalAlert;
