import React from 'react';
import * as PropTypes from 'prop-types';

import { Link } from '../../router';
import { classes, classNamer } from '../../lib/utils';

import './Button.scss';

const cn = classNamer('Button');

const BaseButton = ({ className, children, href, ...passProps }) => {
  if (href) {
    return (
      <Link href={href}>
        <a className={className} {...passProps}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button type="button" className={className} {...passProps}>
      {children}
    </button>
  );
};

const BUTTON_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  dark: 'dark',
};

const Button = ({ variant, noMagnify, className, ...passProps }) => {
  className = classes(cn(), 'btn', 'btn-' + variant, className, !noMagnify && 'magnify');
  return <BaseButton className={className} {...passProps} />;
};

Button.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string,
  noMagnify: PropTypes.bool,
};

export { BUTTON_VARIANTS, Button };
