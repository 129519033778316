export const currencyFormat = (currency = 'USD', minimumFractionDigits = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  });

export const currencyTwoDecimals = amount => currencyFormat('USD', 2).format(amount);

export const currencyFourDecimals = amount => currencyFormat('USD', 4).format(amount);

/**
 * Join all arguments into a className string
 */
export function classes() {
  const results = [];
  for (let i = 0; i < arguments.length; i++) {
    let arg = arguments[i];
    if (!arg) {
      continue;
    }
    if (Array.isArray(arg)) {
      arg = classes.apply(null, arg);
    }
    results.push(String(arg));
  }
  return results.join(' ');
}

/**
 * Returns a function that can be used to generate className-s.
 * eg. classNamer('MyComponent')('sub', 'component') -> 'MyComponent-sub-component'
 * @return function(...string)
 */
export function classNamer(baseName) {
  return (...slugs) => {
    return [baseName, ...slugs].join('-');
  };
}

export const capitalizeFirstLetter = string =>
  string ? string[0].toUpperCase() + string.substring(1) : null;

export const truncateText = (text, maxWords) =>
  text
    ? text.split(' ').length > maxWords
      ? text
          .split(' ')
          .slice(0, maxWords)
          .join(' ') + '...'
      : text
    : null;

export function findParentElement(target, matchFn) {
  while (target) {
    if (matchFn(target)) {
      // Found
      return target;
    }

    target = target.parentElement;
  }

  return null;
}
