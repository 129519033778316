import React from 'react';
import nookies from 'nookies';
import * as PropTypes from 'prop-types';

const LogOutButton = ({ username, onLogOut, ...props }) => {
  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => {
        nookies.destroy({}, 'accessToken');
        nookies.destroy({}, 'username');
        location.reload();
        onLogOut && onLogOut();
      }}
      {...props}
    >
      Log out
    </button>
  );
};

LogOutButton.propTypes = {
  username: PropTypes.string,
  onLogOut: PropTypes.func,
};

export default LogOutButton;
